import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import HoverContext from '../context/HoverContext'
import Sticky from 'react-sticky-el'

// Social share
import {
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton,
	RedditShareButton,
	EmailShareButton,
} from 'react-share'

// Gatsby
import Img from 'gatsby-image'

// Styles
import {
	SPHero,
	// SocialShare,
	InfoWrap,
	PContent,
	AuthorWrap,
	AText,
	Recommended,
	AticleCTA,
} from './style'

// Components
import Layout from '../components/layout'
import SEO from '../components/seo'
import Heading from '../components/ui/Heading'
import Wysiwyg from '../components/ui/Wysiwyg'
import Paragraph from '../components/ui/Paragraph'
import Link from '../components/ui/Link'
import PostRecommended from './postRecommended'

// Images & Icons
import AuthorImage from '../images/author.png'

const Project = React.memo((props) => {
	const {
		Seo,
		FeaturedImage,
		Title,
		createdAt,
		Field,
		posts,
	} = props.data.timacum.post

	const { setCursorStyle } = useContext(HoverContext)

	const Fields = Field

	var monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	]
	let date = new Date(createdAt)

	return (
		<Layout location={props.location} logoWhite>
			<SEO data={Seo} ogImage={FeaturedImage} ogUrl={props.location} />
			<SPHero>
				<img
					loading="lazy"
					src={FeaturedImage.imageFile.publicURL}
					alt={FeaturedImage.alternativeText}
					title={FeaturedImage.caption}
				/>
				<InfoWrap>
					<h1>{Title}</h1>
					<div className="bot-info">
						<p className="date">
							{date.getMonth()} {monthNames[date.getMonth()]}{' '}
							{date.getFullYear()}
						</p>
						<p>Written by Timacum</p>
					</div>
				</InfoWrap>
				<div className="overlay"></div>
			</SPHero>

			<PContent className="article-content">
				<Sticky
					className="social-share"
					// scrollElement=".content"
					bottomOffset={55}
					boundaryElement=".article-content"
					hideOnBoundaryHit={false}
				>
					<TwitterShareButton
						className="twitter-icon"
						url={typeof window !== 'undefined' && window.location.href}
						onMouseEnter={() => {
							setCursorStyle({ height: 0, width: 0, transition: '.1.5s' })
						}}
						onMouseLeave={() => {
							setCursorStyle({ height: false, width: false, transition: false })
						}}
					>
						<svg
							width="36"
							height="36"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="255 864 36 36"
						>
							<path
								className="icon"
								d="M273 864a18 18 0 1 1-18 18 18 18 0 0 1 18-18z"
								fill="#e9e9e9"
							/>
							<path
								data-name="Icon awesome-twitter"
								d="M280.944 878.007c.013.176.013.352.013.528a11.521 11.521 0 0 1-11.663 11.546 11.669 11.669 0 0 1-6.294-1.822 8.565 8.565 0 0 0 .99.05 8.262 8.262 0 0 0 5.089-1.734 4.1 4.1 0 0 1-3.833-2.814 5.22 5.22 0 0 0 .774.063 4.377 4.377 0 0 0 1.08-.138 4.069 4.069 0 0 1-3.288-3.982v-.05a4.16 4.16 0 0 0 1.853.515 4.045 4.045 0 0 1-1.827-3.38 4 4 0 0 1 .562-2.048 11.691 11.691 0 0 0 8.448 4.246 4.537 4.537 0 0 1-.1-.93 4.112 4.112 0 0 1 7.092-2.776 8.12 8.12 0 0 0 2.6-.98 4.055 4.055 0 0 1-1.8 2.236 8.283 8.283 0 0 0 2.36-.628 8.764 8.764 0 0 1-2.056 2.1z"
								fill="#2a2a2a"
							/>
						</svg>
					</TwitterShareButton>
					<FacebookShareButton
						className="facebook-icon"
						url={typeof window !== 'undefined' && window.location.href}
						onMouseEnter={() => {
							setCursorStyle({ height: 0, width: 0, transition: '.1.5s' })
						}}
						onMouseLeave={() => {
							setCursorStyle({ height: false, width: false, transition: false })
						}}
					>
						<svg
							width="36"
							height="36"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="255 912 36 36"
						>
							<g data-name="Share" transform="translate(255 912)">
								<circle
									className="icon"
									data-name="bgd"
									cx="18"
									cy="18"
									r="18"
									fill="#e9e9e9"
								/>
								<path
									data-name="Icon awesome-facebook-f"
									d="M22.01 19.25l.555-3.62h-3.474v-2.348a1.81 1.81 0 0 1 2.041-1.955h1.579V8.245A19.254 19.254 0 0 0 19.909 8c-2.86 0-4.73 1.734-4.73 4.872v2.758H12v3.62h3.18V28h3.911v-8.75z"
									fill="#2a2a2a"
								/>
							</g>
						</svg>
					</FacebookShareButton>
					<LinkedinShareButton
						className="linkedin-icon"
						url={typeof window !== 'undefined' && window.location.href}
						onMouseEnter={() => {
							setCursorStyle({ height: 0, width: 0, transition: '.1.5s' })
						}}
						onMouseLeave={() => {
							setCursorStyle({ height: false, width: false, transition: false })
						}}
					>
						<svg
							width="36"
							height="36"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="255 960 36 36"
						>
							<g data-name="Share" transform="translate(255 960)">
								<circle
									className="icon"
									data-name="bgd"
									cx="18"
									cy="18"
									r="18"
									fill="#e9e9e9"
								/>
								<path
									data-name="Icon awesome-linkedin-in"
									d="M13.029 26.999H9.3V14.982h3.729zm-1.868-13.656a2.171 2.171 0 1 1 2.161-2.182 2.179 2.179 0 0 1-2.161 2.182zM27 26.999h-3.728v-5.85c0-1.394-.028-3.182-1.94-3.182-1.94 0-2.238 1.515-2.238 3.082v5.95h-3.727V14.982h3.579v1.639H19a3.921 3.921 0 0 1 3.531-1.941c3.775.001 4.469 2.488 4.469 5.719v6.6z"
									fill="#2a2a2a"
								/>
							</g>
						</svg>
					</LinkedinShareButton>
					<EmailShareButton
						className="email-icon"
						url={typeof window !== 'undefined' && window.location.href}
						onMouseEnter={() => {
							setCursorStyle({ height: 0, width: 0, transition: '.1.5s' })
						}}
						onMouseLeave={() => {
							setCursorStyle({ height: false, width: false, transition: false })
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="36"
							height="36"
							viewBox="0 0 36 36"
						>
							<g id="Share" transform="translate(-255 -1008)">
								<g
									id="Share-2"
									data-name="Share"
									transform="translate(255 1008)"
								>
									<circle
										className="icon"
										data-name="bgd"
										cx="18"
										cy="18"
										r="18"
										fill="#e9e9e9"
									/>
									<rect
										id="Rectangle_182"
										data-name="Rectangle 182"
										width="21"
										height="15"
										rx="2"
										transform="translate(7.5 10.5)"
										fill="#2a2a2a"
									/>
									<path
										id="Path_857"
										data-name="Path 857"
										d="M0,0,10.352,5.151,21,0V1.874L10.352,7.063,0,1.874Z"
										transform="translate(7.5 12.5)"
										fill="#e9e9e9"
									/>
								</g>
							</g>
						</svg>
					</EmailShareButton>
					<RedditShareButton
						className="reddit-icon"
						url={typeof window !== 'undefined' && window.location.href}
						onMouseEnter={() => {
							setCursorStyle({ height: 0, width: 0, transition: '.1.5s' })
						}}
						onMouseLeave={() => {
							setCursorStyle({ height: false, width: false, transition: false })
						}}
					>
						<svg
							width="36"
							height="36"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="255 1056 36 36"
						>
							<g data-name="Share" transform="translate(255 1056)">
								<circle
									className="icon"
									data-name="bgd"
									cx="18"
									cy="18"
									r="18"
									fill="#e9e9e9"
								/>
								<path
									data-name="Icon awesome-reddit-alien"
									d="M25.77 16.234a2.245 2.245 0 0 0-1.6.67 10.834 10.834 0 0 0-5.779-1.783l1.17-5.264 3.718.835a1.655 1.655 0 0 0 1.652 1.655 1.674 1.674 0 1 0-1.488-2.42l-4.105-.91a.393.393 0 0 0-.464.3l-1.281 5.8a10.969 10.969 0 0 0-5.746 1.8 2.223 2.223 0 1 0-2.584 3.54 4.718 4.718 0 0 0-.11 1.041c0 3.532 3.979 6.395 8.865 6.395 4.907 0 8.886-2.862 8.886-6.395a4.021 4.021 0 0 0-.131-1.058 2.219 2.219 0 0 0-1.003-4.206zm-13.105 4.443a1.663 1.663 0 1 1 1.674 1.652 1.667 1.667 0 0 1-1.674-1.652zm9.034 3.941c-1.534 1.534-5.864 1.534-7.4 0a.393.393 0 0 1 0-.578.405.405 0 0 1 .556 0c1.172 1.2 5.058 1.222 6.281 0a.405.405 0 0 1 .556 0 .39.39 0 0 1 .006.578zm-.034-2.285a1.663 1.663 0 1 1 1.674-1.652 1.655 1.655 0 0 1-1.674 1.652z"
									fill="#2a2a2a"
								/>
							</g>
						</svg>
					</RedditShareButton>
				</Sticky>
				<div className="article-container">
					{Fields.map((field, index) => {
						if (field.Layout === 'title') {
							return (
								<div key={index} className="titleText">
									{field.Title ? (
										<Heading level={field.Tag ? field.Tag.replace('h', '') : 2}>
											{field.Title}
										</Heading>
									) : null}
								</div>
							)
						} else if (field.Layout === 'title-text') {
							return (
								<div key={index} className="titleText">
									{field.Title ? (
										<Heading
											level={
												field.TitleTag ? field.TitleTag.replace('h', '') : 2
											}
										>
											{field.Title}
										</Heading>
									) : null}
									{field.Text ? (
										/\r|\n/.exec(field.Text) ? (
											<Wysiwyg>{field.Text}</Wysiwyg>
										) : (
											<Paragraph>{field.Text}</Paragraph>
										)
									) : null}
								</div>
							)
						} else if (field.Layout === 'text') {
							return (
								<div key={index} className="singleText">
									{field.Text ? (
										/\r|\n/.exec(field.Text) ? (
											<Wysiwyg>{field.Text}</Wysiwyg>
										) : (
											<Paragraph>{field.Text}</Paragraph>
										)
									) : null}
								</div>
							)
						} else if (field.Layout === 'quote') {
							return (
								<div key={index} className="singleText quote">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="64.001"
										height="51"
										viewBox="0 0 64.001 51"
									>
										<path
											id="quote-icon"
											d="M8.355,35.14v25.5h25.6V35.14h-12.8s0-12.75,12.8-12.75V9.64s-25.6,0-25.6,25.5Zm64-12.75V9.64s-25.6,0-25.6,25.5v25.5h25.6V35.14h-12.8S59.555,22.39,72.355,22.39Z"
											transform="translate(72.355 60.64) rotate(180)"
											opacity="0.2"
										/>
									</svg>

									{field.Text ? (
										/\r|\n/.exec(field.Text) ? (
											<Wysiwyg>{field.Text}</Wysiwyg>
										) : (
											<Paragraph>{field.Text}</Paragraph>
										)
									) : null}
								</div>
							)
						} else if (field.Layout === 'image') {
							return (
								<div key={index} className="singleImage">
									{field.Image ? (
										<Img
											className={field.SingleDropShadow ? 'dropShadow ' : ''}
											fluid={field.Image.imageFile.childImageSharp.fluid}
											alt={field.Image.alternativeText}
											title={field.Image.caption}
										/>
									) : null}
								</div>
							)
						} else return null
					})}
					{/*
					<AuthorWrap>
						<img src={AuthorImage} alt="Author avatar" />
						<AText>
							<span>About the author</span>
							<Paragraph>
								<span>Timacum</span> is lorem ipsum dolor sit amet, consetetur
								sadipscing elitr, sed diam nonumy eirmod tempor invidu...{' '}
								<Link to={'/blog'}>Read more articles by Timacum</Link>
							</Paragraph>
						</AText>
					</AuthorWrap>
				*/}
				</div>
			</PContent>
			{Fields.map((field, index) => {
				if (field.Layout === 'article-cta') {
					return (
						<AticleCTA key={index}>
							<div className="text-wrapp">
								<p className="sub">{field.Subtitle}</p>
								<h2>{field.Title}</h2>
								<Link
									className={'link'}
									to={field.Link}
									onMouseEnter={() => {
										setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
									}}
									onMouseLeave={() => {
										setCursorStyle({
											height: false,
											width: false,
											transition: false,
										})
									}}
								>
									{field.CTA}
								</Link>
							</div>
							{field.Image && (
								<div className="img-wrapp">
									{field.Image.imageFile.childImageSharp !== null ? (
										<Img
											className={field.SingleDropShadow ? 'dropShadow ' : ''}
											fluid={field.Image.imageFile.childImageSharp.fluid}
											alt={field.Image.alternativeText}
											title={field.Image.caption}
										/>
									) : (
										<img
											style={{ width: '80%' }}
											loading="lazy"
											src={field.Image.imageFile.publicURL}
											alt={field.Image.imageFile.alternativeText}
											title={field.Image.imageFile.caption}
										/>
									)}
								</div>
							)}
						</AticleCTA>
					)
				}
			})}
			{posts.length !== 0 && (
				<Recommended>
					<Heading level={3}>Recommended reading</Heading>
					<PostRecommended posts={posts} />
				</Recommended>
			)}
		</Layout>
	)
})

export default Project

export const pageQuery = graphql`
	query Post($id: ID!) {
		timacum {
			post(id: $id) {
				id
				Title
				ReadTime
				Seo {
					MetaDescription
					TitleTag
					Url
				}
				createdAt
				FeaturedImage {
					id
					url
					alternativeText
					caption
					imageFile {
						publicURL
						childImageSharp {
							fluid(maxWidth: 1920, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				Field {
					... on TIMACUM_ComponentContentText {
						id
						Text
						Layout
					}
					... on TIMACUM_ComponentContentTitle {
						id
						Layout
						Title
						Tag
					}
					... on TIMACUM_ComponentContentQuote {
						id
						Text
						Layout
					}
					... on TIMACUM_ComponentContentImage {
						id
						Layout
						Image {
							url
							alternativeText
							caption
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1920, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
					... on TIMACUM_ComponentContentVideo {
						id
						Layout
						Link
					}
					... on TIMACUM_ComponentContentTwoImages {
						id
						DesktopMobilePreview
						DoubleDropShadow
						Layout
						FirstImage {
							url
							alternativeText
							caption
							imageFile {
								publicURL
								childImageSharp {
									fluid(maxWidth: 1920, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
						SecondImage {
							url
							alternativeText
							caption
							imageFile {
								publicURL
								childImageSharp {
									fluid(maxWidth: 1920, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
					... on TIMACUM_ComponentContentTitleText {
						id
						Title
						Layout
						Text
						TitleTag
					}
					... on TIMACUM_ComponentContentArticleCta {
						id
						Layout
						CTA
						Link
						Subtitle
						Title
						Image {
							url
							alternativeText
							caption
							imageFile {
								publicURL
								childImageSharp {
									fluid(maxWidth: 1920, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
				}
				posts {
					id
					Title
					ReadTime
					Seo {
						MetaDescription
						TitleTag
						Url
					}
					createdAt
					FeaturedImage {
						id
						url
						alternativeText
						caption
						imageFile {
							publicURL
							childImageSharp {
								fluid(maxWidth: 1920, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					Field {
						... on TIMACUM_ComponentContentText {
							id
							Text
							Layout
						}
						... on TIMACUM_ComponentContentTitleText {
							id
							Title
							Layout
							Text
						}
						... on TIMACUM_ComponentContentArticleCta {
							id
							Layout
							CTA
							Link
							Subtitle
							Title
							Image {
								url
								alternativeText
								caption
								imageFile {
									publicURL
									childImageSharp {
										fluid(maxWidth: 1920, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`
